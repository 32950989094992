import { Sound } from "src/enums";
import {
  enabledSoundsSelector,
  isPlayingSoundSelector,
  setIsPlayingSound,
  useAppDispatch,
  useAppSelector,
} from "src/redux";

const useSound = (sound: Sound) => {
  const soundAudio = new Audio(`/sounds/${sound}.mp3`);
  const isEnabled = useAppSelector(enabledSoundsSelector).includes(sound);
  const isPlayingSound = useAppSelector(isPlayingSoundSelector);
  const dispatch = useAppDispatch();

  const play = () => {
    if (isEnabled && !isPlayingSound) {
      dispatch(setIsPlayingSound(true));
      soundAudio.play();
      setTimeout(() => dispatch(setIsPlayingSound(false)), soundAudio.duration * 1000);
    }
  };

  return { play };
};

export default useSound;
