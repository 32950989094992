import React, { useEffect, useRef, useState } from "react";
import { ContextMenuState } from "src/enums";
import {
  isDraggingStripItemSelector,
  setContextMenuSpec,
  setSelectedStripItem,
  useAppDispatch,
  useAppSelector,
} from "src/redux";
import * as S from "src/styles/flightStrips";
import StripItem from "./StripItem";

interface StripRackProps {
  itemIds: string[];
  rackNumber: number;
}

function StripRack({ itemIds, rackNumber }: Readonly<StripRackProps>) {
  const isDraggingStripItem = useAppSelector(isDraggingStripItemSelector);
  const dispatch = useAppDispatch();

  const [scrollUpIsEnabled, setScrollUpIsEnabled] = useState(false);
  const [scrollDownIsEnabled, setScrollDownIsEnabled] = useState(false);
  const rackRef = useRef<HTMLDivElement>(undefined!);

  const drawTriangles = () => {
    const rackElement = rackRef.current;
    if (rackElement) {
      const { scrollHeight, clientHeight, scrollTop } = rackElement;
      if (scrollHeight > clientHeight && scrollTop < 0) {
        setScrollDownIsEnabled(true);
      } else {
        setScrollDownIsEnabled(false);
      }
      if (scrollHeight > clientHeight && scrollTop > clientHeight - scrollHeight + 1) {
        setScrollUpIsEnabled(true);
      } else {
        setScrollUpIsEnabled(false);
      }
    }
  };

  useEffect(drawTriangles, [itemIds]);

  useEffect(() => {
    window.addEventListener("resize", drawTriangles);

    return () => {
      window.removeEventListener("resize", drawTriangles);
    };
  }, [rackRef]);

  return (
    <S.RackContainer
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setSelectedStripItem(undefined));
        dispatch(
          setContextMenuSpec({ state: ContextMenuState.Rack, x: e.pageX, y: e.pageY, selectedRack: rackNumber }),
        );
      }}
    >
      {scrollUpIsEnabled && <S.UpTriangle />}
      <S.Rack ref={rackRef} onScroll={drawTriangles} data-rack-number={rackNumber}>
        {itemIds.map((i) => (
          <StripItem stripItemId={i} key={i} />
        ))}
        {isDraggingStripItem && (
          <S.StripItemPlaceholdersContainer>
            <S.StripItemPlaceholder />
            {itemIds.map((itemId, i) => (
              <S.StripItemPlaceholder
                key={`placeholder-${itemId}`}
                data-strip-item-placeholder-index={itemIds.length - i - 1}
              />
            ))}
          </S.StripItemPlaceholdersContainer>
        )}
      </S.Rack>
      {scrollDownIsEnabled && <S.DownTriangle />}
    </S.RackContainer>
  );
}

export default StripRack;
