import { StripItemDto } from "@vatsim-vnas/js-libs/models/vnas/messaging";
import React, { MouseEvent } from "react";
import ContextMenuState from "src/enums/ContextMenuState";
import {
  isReadOnlySelector,
  setContextMenuSpec,
  setSelectedStripItem,
  useAppDispatch,
  useAppSelector,
} from "src/redux";
import * as S from "src/styles/flightStrips";
import AnnotationBox from "./AnnotationBox";

interface BlankStripProps {
  stripItem: StripItemDto;
}

function BlankStrip({ stripItem }: Readonly<BlankStripProps>) {
  const isReadOnly = useAppSelector(isReadOnlySelector);
  const dispatch = useAppDispatch();

  const handleContextMenu = (e: MouseEvent) => {
    if (!isReadOnly) {
      e.preventDefault();
      e.stopPropagation();
      dispatch(setSelectedStripItem(stripItem.id));
      dispatch(setContextMenuSpec({ state: ContextMenuState.FlightStrip, x: e.pageX, y: e.pageY }));
    }
  };

  return (
    <S.FlightPlanStrip onContextMenu={handleContextMenu}>
      <AnnotationBox x={3} y={44} width={117} textAlign="left" stripItem={stripItem} fieldNumber={0} />
      <AnnotationBox x={3} y={22} width={117} textAlign="left" stripItem={stripItem} fieldNumber={1} />
      <AnnotationBox x={3} y={0} width={117} textAlign="left" stripItem={stripItem} fieldNumber={2} />
      <S.VerticalLine x={120} />
      <AnnotationBox x={120} y={44} width={45} stripItem={stripItem} fieldNumber={3} />
      <AnnotationBox x={120} y={22} width={45} stripItem={stripItem} fieldNumber={4} />
      <AnnotationBox x={120} y={0} width={45} stripItem={stripItem} fieldNumber={5} />
      <S.HorizontalLine x={120} y={22} width={45} />
      <S.HorizontalLine x={120} y={44} width={45} />
      <S.VerticalLine x={165} />
      <AnnotationBox x={168} y={44} width={72} textAlign="left" stripItem={stripItem} fieldNumber={6} />
      <AnnotationBox x={168} y={22} width={72} textAlign="left" stripItem={stripItem} fieldNumber={7} />
      <AnnotationBox x={168} y={0} width={72} textAlign="left" stripItem={stripItem} fieldNumber={8} />
      <S.VerticalLine x={240} />
      <AnnotationBox
        x={243}
        y={0}
        width={202}
        height={66}
        multiLine
        textAlign="left"
        stripItem={stripItem}
        fieldNumber={9}
      />
      <S.VerticalLine x={445} />
      <S.VerticalLine x={475} />
      <S.VerticalLine x={505} />
      <S.HorizontalLine x={445} y={22} width={90} />
      <S.HorizontalLine x={445} y={44} width={90} />
      <AnnotationBox x={475} y={44} stripItem={stripItem} fieldNumber={10} />
      <AnnotationBox x={505} y={44} stripItem={stripItem} fieldNumber={11} />
      <AnnotationBox x={445} y={22} stripItem={stripItem} fieldNumber={12} />
      <AnnotationBox x={475} y={22} stripItem={stripItem} fieldNumber={13} />
      <AnnotationBox x={505} y={22} stripItem={stripItem} fieldNumber={14} />
      <AnnotationBox x={445} y={0} stripItem={stripItem} fieldNumber={15} />
      <AnnotationBox x={475} y={0} stripItem={stripItem} fieldNumber={16} />
      <AnnotationBox x={505} y={0} stripItem={stripItem} fieldNumber={17} />
      <AnnotationBox x={445} y={44} stripItem={stripItem} fieldNumber={18} />
    </S.FlightPlanStrip>
  );
}

export default BlankStrip;
