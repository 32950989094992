import { FlightStripsStateDto } from "@vatsim-vnas/js-libs/models/vnas/messaging";
import { deepCopy } from "@vatsim-vnas/js-libs/utils";
import React, { createContext, ReactNode, useContext, useMemo } from "react";
import { Sound } from "src/enums";
import { useSound } from "src/hooks";
import {
  activeBayIdSelector,
  configurationSelector,
  draggingStripItemSpecSelector,
  facilityIdSelector,
  removeAnimatingStripItem,
  sessionSelector,
  setDisplacingPosition,
  setDraggingStripItemSpec,
  setFlightStripsState,
  useAppDispatch,
  useAppSelector,
} from "src/redux";

interface FlightStripsInterface {
  receiveFlightStripsState: (newState: FlightStripsStateDto) => void;
}

const FlightStripsInterfaceContext = createContext<FlightStripsInterface>(undefined!);

interface FlightStripsInterfaceProviderProps {
  children: ReactNode;
}

export function FlightStripsInterfaceProvider({ children }: Readonly<FlightStripsInterfaceProviderProps>) {
  const facilityId = useAppSelector(facilityIdSelector);
  const configuration = useAppSelector(configurationSelector);
  const draggingStripItemSpec = useAppSelector(draggingStripItemSpecSelector);
  const activeBayId = useAppSelector(activeBayIdSelector);
  const sessionId = useAppSelector(sessionSelector)?.id;
  const dispatch = useAppDispatch();
  const printerSound = useSound(Sound.Printer);
  const arrivalPrinterSound = useSound(Sound.ArrivalPrinter);
  const slideSound = useSound(Sound.Slide);

  const deleteDraggingStripItem = (stripItemId: string) => {
    dispatch(setDraggingStripItemSpec(undefined));
    dispatch(setDisplacingPosition(undefined));
    document.querySelectorAll(`[data-strip-item-clone-id='${stripItemId}']`).forEach((el) => el.remove());
    dispatch(removeAnimatingStripItem(stripItemId));
  };

  const removeDraggingStripItemFromState = (stripItemId: string, newState: FlightStripsStateDto) => {
    newState.printerItems = newState.printerItems.filter((i) => i !== stripItemId);

    for (const bay of newState.bayItems) {
      for (const rack of bay.itemIds) {
        for (let i = 0; i < rack.length; i++) {
          if (rack[i] === stripItemId) {
            rack.splice(i, 1);
          }
        }
      }
    }
  };

  const handleDraggingStripItem = (newState: FlightStripsStateDto) => {
    const stripItemId = draggingStripItemSpec?.stripItemId;
    if (!stripItemId) {
      return;
    }

    if (
      !newState.printerItems.includes(stripItemId) &&
      !newState.bayItems
        .map((b) => b.itemIds)
        .flat(2)
        .includes(stripItemId)
    ) {
      deleteDraggingStripItem(stripItemId);
    } else {
      removeDraggingStripItemFromState(stripItemId, newState);
    }
  };

  const handlePrinterSound = (newState: FlightStripsStateDto) => {
    if (newState.newItemInPrinter || newState.newItemInArrivalPrinter) {
      const playArrivalPrinterSound =
        configuration?.enableArrivalStrips &&
        configuration?.enableSeparateArrDepPrinters &&
        newState.newItemInArrivalPrinter;
      const sound = playArrivalPrinterSound ? arrivalPrinterSound : printerSound;
      sound.play();
    }
  };

  const handleSlideSound = (newState: FlightStripsStateDto) => {
    if (newState.newItemInBayId === activeBayId && newState.itemMovedOrCreatedBySessionId !== sessionId) {
      slideSound.play();
    }
  };

  const handleUpdateTitle = (newState: FlightStripsStateDto) => {
    const newNumberOfItemsInPrinter = newState.printerItems.length;
    if (!facilityId) {
      document.title = "vStrips";
    } else if (newNumberOfItemsInPrinter > 0) {
      document.title = `(${newNumberOfItemsInPrinter}) vStrips | ${facilityId}`;
    } else {
      document.title = `vStrips | ${facilityId}`;
    }
  };

  const receiveFlightStripsState = (newState: FlightStripsStateDto) => {
    const newStateCopy = deepCopy(newState);
    handleDraggingStripItem(newStateCopy);
    handlePrinterSound(newStateCopy);
    handleSlideSound(newStateCopy);
    handleUpdateTitle(newStateCopy);
    dispatch(setFlightStripsState(newStateCopy));
  };

  const functions = useMemo(() => ({ receiveFlightStripsState }), [receiveFlightStripsState, facilityId]);

  return <FlightStripsInterfaceContext.Provider value={functions}>{children}</FlightStripsInterfaceContext.Provider>;
}

const useFlightStripsInterface = () => {
  return useContext(FlightStripsInterfaceContext);
};

export default useFlightStripsInterface;
