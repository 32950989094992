import React, { useEffect, useImperativeHandle, useState } from "react";
import { StripItem } from "src/components/flightStrips";
import { useFlightStrips, useKey } from "src/hooks";
import {
  isReadOnlySelector,
  printerMenuIsActiveSelector,
  setSelectedStripItem,
  useAppDispatch,
  useAppSelector,
} from "src/redux";
import * as S from "src/styles/menus";
import { Button } from "src/styles/ui";

interface PrinterProps {
  disableKeyBinds: boolean;
  emptyMessage: string;
  printerItemIds: string[];
}

export interface PrinterHandle {
  resetCurrentStripNumber: () => void;
}

const Printer = React.forwardRef<PrinterHandle, PrinterProps>(
  ({ emptyMessage, printerItemIds, disableKeyBinds }, ref) => {
    const isReadOnly = useAppSelector(isReadOnlySelector);
    const printerMenuIsActive = useAppSelector(printerMenuIsActiveSelector);
    const dispatch = useAppDispatch();

    const [currentStrip, setCurrentStrip] = useState<string>();
    const { moveStripItemFromPrinter, deleteStripItem } = useFlightStrips();

    useImperativeHandle(ref, () => ({
      resetCurrentStripNumber: () => {
        setCurrentStrip(undefined);
      },
    }));

    useEffect(() => {
      if (!printerMenuIsActive) {
        setCurrentStrip(undefined);
      } else {
        dispatch(setSelectedStripItem(undefined));
      }
    }, [printerMenuIsActive]);

    function getCurrentIndex() {
      return printerItemIds.indexOf(currentStrip!);
    }

    const incrementCurrentStripNumber = () => {
      const newIndex = (getCurrentIndex() - 1 + printerItemIds.length) % printerItemIds.length;
      setCurrentStrip(printerItemIds[newIndex]);
    };

    const decrementCurrentStripNumber = () => {
      const newIndex = (getCurrentIndex() + 1) % printerItemIds.length;
      setCurrentStrip(printerItemIds[newIndex]);
    };

    const handleDelete = () => {
      if (printerItemIds.length && currentStrip) {
        deleteStripItem(currentStrip);
      }
    };

    const handleMoveToBay = () => {
      if (currentStrip) {
        moveStripItemFromPrinter(currentStrip);
      }
    };

    useKey("Enter", handleMoveToBay, { printerMenuIsActive: true, isReadOnly: false, disabled: disableKeyBinds });
    useKey("Delete", handleDelete, {
      printerMenuIsActive: true,
      isReadOnly: false,
      alternateKeys: ["Backspace"],
      disabled: disableKeyBinds,
    });
    useKey("ArrowLeft", decrementCurrentStripNumber, {
      printerMenuIsActive: true,
      alternateKeys: ["PageDown"],
      disabled: disableKeyBinds,
    });
    useKey("ArrowRight", incrementCurrentStripNumber, {
      printerMenuIsActive: true,
      alternateKeys: ["PageUp"],
      disabled: disableKeyBinds,
    });

    if (printerItemIds.length && (!currentStrip || !printerItemIds.includes(currentStrip))) {
      setCurrentStrip(printerItemIds[printerItemIds.length - 1]);
    }

    return printerItemIds.length ? (
      <>
        <S.StripItemContainer>
          <S.LeftTriangle onClick={decrementCurrentStripNumber} />
          <StripItem stripItemId={currentStrip!} />
          <S.RightTriangle onClick={incrementCurrentStripNumber} />
        </S.StripItemContainer>
        <S.Description>
          {printerItemIds.length - getCurrentIndex()}/{printerItemIds.length}
        </S.Description>
        <S.ButtonContainer>
          <Button className="primary" disabled={isReadOnly} onClick={handleMoveToBay}>
            Move to Bay
          </Button>
          <Button className="danger" disabled={isReadOnly} onClick={handleDelete}>
            Delete
          </Button>
        </S.ButtonContainer>
      </>
    ) : (
      <S.Description>{emptyMessage}</S.Description>
    );
  },
);

Printer.displayName = "Printer";

export default Printer;
