import { HALF_STRIP_TYPES, SEPARATOR_TYPES } from "@vatsim-vnas/js-libs/constants";
import {
  MoveStripItemResult,
  StripBayContentsDto,
  StripItemDto,
  StripItemType,
  isLockableSeparator,
} from "@vatsim-vnas/js-libs/models/vnas/messaging";
import {
  cycleNextItemIn2DArray,
  cycleNextItemInArray,
  cyclePreviousItemIn2DArray,
  cyclePreviousItemInArray,
  deepCopy,
} from "@vatsim-vnas/js-libs/utils";
import { instanceToInstance } from "class-transformer";
import React, { ReactNode, createContext, useContext, useMemo } from "react";
import { toast } from "react-toastify";
import { Direction, Sound } from "src/enums";
import { useHub, useSound } from "src/hooks";
import { DraggingStripItemSpec, StripItemPosition } from "src/models";
import {
  activeBayIdSelector,
  addAnimatingStripItem as addAnimatingStripItemInState,
  addOrUpdateStripItem as addOrUpdateStripItemInState,
  baysContentsSelector,
  configurationSelector,
  defaultRackSelector,
  draggingStripItemSpecSelector,
  externalBaysSelector,
  facilityIdSelector,
  getFlightStripsConfiguration,
  printerItemIdsSelector,
  removeAnimatingStripItem as removeAnimatingStripItemInState,
  removeItemFromPrinter as removeItemFromPrinterInState,
  resetUiState,
  selectedStripItemIdSelector,
  setActiveBay as setActiveBayInState,
  setAnimationsDisabled as setAnimationsDisabledInState,
  setBaysContents as setBaysContentsInState,
  setDisplacingPosition as setDisplacingPositionInState,
  setDraggingStripItemSpec as setDraggingStripItemSpecInState,
  setFacility as setFacilityInState,
  setPrinterMenuIsActive as setPrinterMenuIsActiveInState,
  setSelectedStripItem as setSelectedStripItemInState,
  stripItemsSelector,
  useAppDispatch,
  useAppSelector,
} from "src/redux";
import { DISPLACE_ANIMATION_MS, DROP_ANIMATION_MS, debugLog } from "src/utils";

interface FlightStrips {
  setFacility: (newFacilityId: string) => void;
  getStripItem: (stripItemId: string) => StripItemDto;
  getBayId: (bayIndex: number) => string;
  getBay: (bayId: string) => StripBayContentsDto;
  setActiveBay: (bayId: string) => void;
  cycleActiveBay: (next: boolean) => void;
  getFirstPositionOnRack: (bayId: string, rack: number) => StripItemPosition;
  getStripItemPosition: (stripItemId: string) => StripItemPosition | undefined;
  selectAdjacentStripItem: (direction: Direction) => void;
  focusStripItemField: (stripItemId: string, fieldNumber: number) => void;
  createStripItem: (stripItem: StripItemDto, position: StripItemPosition) => void;
  moveSelectedStripItem: (direction: Direction) => void;
  cycleStripItemType: (stripItemId: string, next: boolean) => void;
  editStripItemType: (stripItemId: string, type: StripItemType) => void;
  pushStripItemToBay: (stripItemId: string, bayId: string) => void;
  dragStripItem: (stripItemId: string, pageX: number, pageY: number) => void;
  deleteStripItem: (stripItemId: string) => void;
  toggleStripItemOffset: (stripItemId: string) => void;
  editStripItemField: (stripItemId: string, fieldNumber: number, value: string) => void;
  getDraggingStripElement: (stripItemId: string) => HTMLDivElement;
  returnDraggingStripItem: () => void;
  moveDraggingStripItem: (position: StripItemPosition, toBayButton?: boolean) => void;
  moveStripItemFromPrinter: (stripItemId: string) => void;
  deleteDraggingStripItem: () => void;
}

const FlightStripsContext = createContext<FlightStrips>(undefined!);

interface FlightStripsPrinterProps {
  children: ReactNode;
}

export function FlightStripsProvider({ children }: Readonly<FlightStripsPrinterProps>) {
  const stripItems = useAppSelector(stripItemsSelector);
  const baysContents = useAppSelector(baysContentsSelector);
  const configuration = useAppSelector(configurationSelector)!;
  const activeBayId = useAppSelector(activeBayIdSelector);
  const selectedStripItemId = useAppSelector(selectedStripItemIdSelector);
  const externalBays = useAppSelector(externalBaysSelector);
  const printerItemIds = useAppSelector(printerItemIdsSelector);
  const facilityId = useAppSelector(facilityIdSelector)!;
  const draggingStripItemSpec = useAppSelector(draggingStripItemSpecSelector);
  const defaultRack = useAppSelector(defaultRackSelector);
  const dispatch = useAppDispatch();
  const trashSound = useSound(Sound.Trash);

  const hub = useHub();

  const invokeHubMethod = async (methodName: string, args: unknown, successMessage?: string, errorMessage?: string) => {
    try {
      const res = await hub.invoke(methodName, args);
      if (
        methodName === "MoveStripItem" &&
        (res as MoveStripItemResult) === MoveStripItemResult.PushedToVacantFacility
      ) {
        toast.warn("Flight strip was not received because facility is not active.", { autoClose: 10000 });
        debugLog("Flight strip was not received because facility is not active.");
      }
      debugLog(successMessage);
    } catch (e) {
      debugLog(errorMessage);
      toast.error(`${errorMessage}: ${e}`);
      hub.invoke("RequestFullFlightStripsState", facilityId);
    }
  };

  // Public
  const setFacility = (newFacilityId: string) => {
    if (facilityId) {
      invokeHubMethod("Unsubscribe", {
        Category: "FlightStrips",
        FacilityId: facilityId,
      });
    }
    dispatch(resetUiState());
    dispatch(setFacilityInState(newFacilityId));
    dispatch(getFlightStripsConfiguration(newFacilityId));
    invokeHubMethod("Subscribe", {
      Category: "FlightStrips",
      FacilityId: newFacilityId,
    });
  };

  // Public
  const getBayId = (bayIndex: number) => {
    if (bayIndex < baysContents.length) {
      return baysContents[bayIndex].bayId;
    }
    return externalBays[bayIndex - baysContents.length].id;
  };

  // Public
  const getBay = (bayId: string, newBaysContents?: StripBayContentsDto[]) => {
    return (newBaysContents ?? baysContents).find((b) => b.bayId === bayId)!;
  };

  const getFirstStripItemIdInBay = (bayId: string) => {
    let firstStripItemId: string | undefined;
    getBay(bayId).itemIds.every((r) => {
      if (r.length) {
        [firstStripItemId] = r;
        return false;
      }
      return true;
    });
    return firstStripItemId;
  };

  // Public
  const setActiveBay = (bayId: string) => {
    dispatch(setSelectedStripItemInState(undefined));
    dispatch(setActiveBayInState(bayId));
  };

  // Public
  const cycleActiveBay = (next: boolean) => {
    const currentIndex = configuration.stripBays.findIndex((b) => b.id === activeBayId);
    if (next) {
      setActiveBay(cycleNextItemInArray(configuration.stripBays, currentIndex).id);
    } else {
      setActiveBay(cyclePreviousItemInArray(configuration.stripBays, currentIndex).id);
    }
  };

  const getRack = (bayId: string, rack: number, newBaysContents?: StripBayContentsDto[]) => {
    return getBay(bayId, newBaysContents).itemIds[rack];
  };

  const getNumberOfItemsOnRack = (bayId: string, rack: number) => {
    return getRack(bayId, rack).length;
  };

  // Public
  const getFirstPositionOnRack = (bayId: string, rack: number) => {
    return { bayId, rack, index: getNumberOfItemsOnRack(bayId, rack), facilityId } as StripItemPosition;
  };

  // Public
  const getStripItem = (stripItemId: string) => {
    return stripItems.find((s) => s.id === stripItemId)!;
  };

  // Public
  const getStripItemPosition = (stripItemId: string) => {
    for (const bay of baysContents) {
      for (let r = 0; r < bay.itemIds.length; r++) {
        for (let i = 0; i < bay.itemIds[r].length; i++) {
          if (bay.itemIds[r][i] === stripItemId) {
            return { bayId: bay.bayId, rack: r, index: i, facilityId } as StripItemPosition;
          }
        }
      }
    }
    return undefined;
  };

  // Public
  const selectAdjacentStripItem = (direction: Direction) => {
    if (!selectedStripItemId) {
      const firstStripItemId = getFirstStripItemIdInBay(activeBayId);
      dispatch(setSelectedStripItemInState(firstStripItemId));
      return firstStripItemId;
    }
    const selectedStripItemPosition = getStripItemPosition(selectedStripItemId)!;
    const activeBay = getBay(activeBayId);
    let nextStripItemId: string | undefined;
    switch (direction) {
      case Direction.Up: {
        nextStripItemId = cycleNextItemInArray(
          activeBay.itemIds[selectedStripItemPosition.rack],
          selectedStripItemPosition.index,
        );
        break;
      }
      case Direction.Down: {
        nextStripItemId = cyclePreviousItemInArray(
          activeBay.itemIds[selectedStripItemPosition.rack],
          selectedStripItemPosition.index,
        );
        break;
      }
      case Direction.Right: {
        nextStripItemId = cycleNextItemIn2DArray(
          activeBay.itemIds,
          selectedStripItemPosition.rack,
          selectedStripItemPosition.index,
        );
        break;
      }
      case Direction.Left: {
        nextStripItemId = cyclePreviousItemIn2DArray(
          activeBay.itemIds,
          selectedStripItemPosition.rack,
          selectedStripItemPosition.index,
        );
        break;
      }
      default:
        break;
    }
    dispatch(setSelectedStripItemInState(nextStripItemId));
    return nextStripItemId;
  };

  // Public
  const focusStripItemField = (stripItemId: string, fieldNumber: number, newStripItem?: StripItemDto) => {
    const stripItem = newStripItem ?? getStripItem(stripItemId);
    if (stripItem.type === StripItemType.DepartureStrip || stripItem.type === StripItemType.ArrivalStrip) {
      (
        document.querySelectorAll(`[data-strip-item-id='${stripItem.id}'] > div > input`)[
          fieldNumber
        ] as HTMLInputElement
      ).focus();
    } else if (stripItem.isSeparator() && !(isLockableSeparator(stripItem.type) && configuration.lockSeparators)) {
      (document.querySelector(`[data-strip-item-id='${stripItem.id}'] > div > input`) as HTMLInputElement).focus();
    } else if (stripItem.isHalfStrip()) {
      (
        document.querySelector(`[data-strip-item-id='${stripItem.id}']  > div > textarea`) as HTMLTextAreaElement
      ).focus();
    }
  };

  const updateStripItem = (stripItem: StripItemDto) => {
    dispatch(addOrUpdateStripItemInState(stripItem));
    invokeHubMethod("UpdateStripItem", { facilityId, stripItem }, "Updated strip item", "Error updating strip item");
  };

  // Public
  const toggleStripItemOffset = (stripItemId: string) => {
    const stripItem = instanceToInstance(getStripItem(stripItemId));
    if (!stripItem.isSeparator()) {
      stripItem.isOffset = !stripItem.isOffset;
      updateStripItem(stripItem);
    }
  };

  // Public
  const editStripItemField = (stripItemId: string, fieldNumber: number, value: string) => {
    const stripItem = instanceToInstance(getStripItem(stripItemId));
    if (stripItem.fieldValues[fieldNumber] !== value) {
      stripItem.fieldValues[fieldNumber] = value;
      updateStripItem(stripItem);
    }
  };

  // Public
  const editStripItemType = (stripItemId: string, type: StripItemType) => {
    const stripItem = instanceToInstance(getStripItem(stripItemId));
    stripItem.type = type;
    updateStripItem(stripItem);
  };

  // Public
  const cycleStripItemType = (stripItemId: string, next: boolean) => {
    const stripItem = getStripItem(stripItemId);
    if (stripItem.isHalfStrip()) {
      const currentIndex = HALF_STRIP_TYPES.findIndex((t) => t === stripItem.type);
      editStripItemType(stripItemId, cycleNextItemInArray(HALF_STRIP_TYPES, currentIndex));
    }
    if (stripItem.isSeparator() && !(isLockableSeparator(stripItem.type) && configuration.lockSeparators)) {
      const currentIndex = SEPARATOR_TYPES.findIndex((t) => t === stripItem.type);
      if (next) {
        editStripItemType(stripItemId, cycleNextItemInArray(SEPARATOR_TYPES, currentIndex));
      } else {
        editStripItemType(stripItemId, cyclePreviousItemInArray(SEPARATOR_TYPES, currentIndex));
      }
    }
  };

  const addStripItem = (stripItem: string, position: StripItemPosition, newBaysContents?: StripBayContentsDto[]) => {
    const newBaysContents1 = newBaysContents ?? deepCopy(baysContents);
    const rack = getRack(position.bayId, position.rack, newBaysContents1);
    rack.splice(position.index, 0, stripItem);
    dispatch(setBaysContentsInState(newBaysContents1));
  };

  // Public
  const createStripItem = (stripItem: StripItemDto, position: StripItemPosition) => {
    dispatch(addOrUpdateStripItemInState(stripItem));
    addStripItem(stripItem.id, position);
    invokeHubMethod("CreateStripItem", { stripItem, position }, "Created strip item", "Error creating strip item");
    dispatch(setSelectedStripItemInState(stripItem.id));
    if (stripItem.isSeparator() || stripItem.isHalfStrip()) {
      setTimeout(() => {
        focusStripItemField(stripItem.id, 0, stripItem);
      });
    }
  };

  const moveStripItem = (stripItemId: string, position: StripItemPosition, newBaysContents?: StripBayContentsDto[]) => {
    const newBaysContents1 = newBaysContents ?? removeStripItem(stripItemId);
    if (position.facilityId === facilityId) {
      addStripItem(stripItemId, position, newBaysContents1);
    }
    invokeHubMethod(
      "MoveStripItem",
      { facilityId, itemId: stripItemId, position },
      "Moved strip item",
      "Error moving strip item",
    );
  };

  const removeStripItem = (stripItemId: string) => {
    const newBaysContents = deepCopy(baysContents);
    const position = getStripItemPosition(stripItemId)!;
    const rack = getRack(position.bayId, position.rack, newBaysContents);
    rack.splice(position.index, 1);
    dispatch(setBaysContentsInState(newBaysContents));
    return deepCopy(newBaysContents);
  };

  // Public
  const moveSelectedStripItem = (direction: Direction) => {
    const position = getStripItemPosition(selectedStripItemId!)!;
    let changedPosition = false;
    switch (direction) {
      case Direction.Up: {
        const maxIndex = getNumberOfItemsOnRack(position.bayId, position.rack) - 1;
        if (position.index < maxIndex) {
          position.index += 1;
          changedPosition = true;
        }
        break;
      }
      case Direction.Down: {
        if (position.index > 0) {
          position.index -= 1;
          changedPosition = true;
        }
        break;
      }
      case Direction.Right: {
        const maxRack = getBay(activeBayId).itemIds.length - 1;
        if (position.rack < maxRack) {
          position.rack += 1;
          position.index = getNumberOfItemsOnRack(activeBayId, position.rack);
          changedPosition = true;
        }
        break;
      }
      case Direction.Left: {
        if (position.rack > 0) {
          position.rack -= 1;
          position.index = getNumberOfItemsOnRack(activeBayId, position.rack);
          changedPosition = true;
        }
        break;
      }
      default:
        break;
    }

    if (changedPosition) {
      moveStripItem(selectedStripItemId!, position);
    }
  };

  const selectNextStripItem = () => {
    const position = getStripItemPosition(selectedStripItemId!)!;
    const oldSelectedStripItemId = selectedStripItemId!;
    const newSelectedStripItemId =
      position.index === 0 ? selectAdjacentStripItem(Direction.Up) : selectAdjacentStripItem(Direction.Down);
    if (newSelectedStripItemId === oldSelectedStripItemId) {
      dispatch(setSelectedStripItemInState(undefined));
    } else {
      dispatch(setSelectedStripItemInState(newSelectedStripItemId));
    }
  };

  const cloneStripItem = (stripItemId: string, rotate: boolean, zIndex?: string) => {
    document.querySelectorAll(`[data-strip-item-clone-id='${stripItemId}']`).forEach((el) => el.remove());
    const stripItemElement = document.querySelector(`[data-strip-item-id='${stripItemId}']`)!;
    stripItemElement.classList.remove("selected");
    const { top, left } = stripItemElement.getBoundingClientRect();
    const clonedElement = stripItemElement.cloneNode(true) as HTMLElement;
    clonedElement.style.top = `${top}px`;
    clonedElement.style.left = `${left}px`;
    if (rotate) {
      clonedElement.style.transform = `rotate(10deg)`;
    }
    clonedElement.style.zIndex = zIndex ?? "";
    clonedElement.removeAttribute("data-strip-item-id");
    clonedElement.setAttribute("data-strip-item-clone-id", stripItemId);
    document.getElementById("draggable-clone-container")!.appendChild(clonedElement);
  };

  // Public
  const pushStripItemToBay = (stripItemId: string, bayId: string) => {
    if (activeBayId === bayId) {
      return;
    }
    const isExternal = baysContents.find((b) => b.bayId === bayId) === undefined;

    const origin = getStripItemPosition(stripItemId)!;
    let newPosition: StripItemPosition;
    if (!isExternal) {
      newPosition = getFirstPositionOnRack(
        bayId,
        (configuration.stripBays.find((b) => b.id === bayId)!.defaultRack ?? 1) - 1,
      );
    } else {
      const externalFacilityId = configuration.externalBays.find((b) => b.bayId === bayId)!.facilityId;
      newPosition = { bayId, rack: 0, index: -1, facilityId: externalFacilityId } as StripItemPosition;
    }

    selectNextStripItem();
    cloneStripItem(stripItemId, false);
    dispatch(setAnimationsDisabledInState(true));
    dispatch(setDisplacingPositionInState(origin));
    const newBayContents = removeStripItem(stripItemId);
    animateDraggingStrip(stripItemId, bayId);
    setTimeout(() => {
      moveStripItem(stripItemId, newPosition, newBayContents);
      dispatch(setDisplacingPositionInState(undefined));
      dispatch(setAnimationsDisabledInState(false));
    }, DISPLACE_ANIMATION_MS);
  };

  // Public
  const deleteStripItem = (stripItemId: string) => {
    if (printerItemIds.includes(stripItemId)) {
      dispatch(removeItemFromPrinterInState(stripItemId));
      invokeHubMethod(
        "DeleteStripItem",
        { facilityId, itemId: stripItemId },
        "Deleted strip item",
        "Error deleting strip item",
      );
    } else {
      const stripItem = getStripItem(stripItemId);
      if (isLockableSeparator(stripItem.type) && configuration.lockSeparators) {
        return;
      }
      const origin = getStripItemPosition(stripItemId)!;
      if (selectedStripItemId) {
        selectNextStripItem();
      }
      dispatch(setAnimationsDisabledInState(true));
      dispatch(setDisplacingPositionInState(origin));
      cloneStripItem(stripItemId, false);
      animateDraggingStrip(stripItemId);
      removeStripItem(stripItemId);
      setTimeout(() => {
        dispatch(setAnimationsDisabledInState(false));
        dispatch(setDisplacingPositionInState(undefined));
        invokeHubMethod(
          "DeleteStripItem",
          { facilityId, itemId: stripItemId },
          "Deleted strip item",
          "Error deleting strip item",
        );
      }, DISPLACE_ANIMATION_MS);
    }
  };

  // Public
  const dragStripItem = (stripItemId: string, pageX: number, pageY: number) => {
    dispatch(setSelectedStripItemInState(undefined));
    dispatch(setAnimationsDisabledInState(true));
    const stripItemElement = document.querySelector(`[data-strip-item-id='${stripItemId}']`)!;
    stripItemElement.querySelectorAll("input, textarea").forEach((i) => {
      (i as HTMLElement).style.pointerEvents = "none";
    });
    const { top, left } = stripItemElement.getBoundingClientRect();

    const stripItem = getStripItem(stripItemId);
    if (stripItem.isOffset) {
      toggleStripItemOffset(stripItemId);
    }
    const deletable = !(isLockableSeparator(stripItem.type) && configuration.lockSeparators);

    cloneStripItem(stripItemId, true);
    if (printerItemIds.includes(stripItemId)) {
      const origin = getFirstPositionOnRack(activeBayId, 0);
      dispatch(setPrinterMenuIsActiveInState(false));
      dispatch(removeItemFromPrinterInState(stripItemId));
      dispatch(
        setDraggingStripItemSpecInState(
          new DraggingStripItemSpec(stripItemId, origin, pageX - left, pageY - top, deletable),
        ),
      );
    } else {
      const origin = getStripItemPosition(stripItemId)!;
      dispatch(
        setDraggingStripItemSpecInState(
          new DraggingStripItemSpec(stripItemId, origin, pageX - left, pageY - top, deletable),
        ),
      );
      dispatch(setDisplacingPositionInState(origin));
      removeStripItem(stripItemId);
    }
  };

  // Public
  const moveStripItemFromPrinter = (stripItemId: string) => {
    cloneStripItem(stripItemId, false, "49");
    let rack = defaultRack ?? configuration.stripBays.find((b) => b.id === activeBayId)!.defaultRack;
    if (rack !== undefined) {
      rack--;
      rack = Math.min(Math.max(0, rack), configuration.stripBays.find((b) => b.id === activeBayId)!.numberOfRacks - 1);
    } else {
      rack = 0;
    }
    const position = getFirstPositionOnRack(activeBayId, rack);
    dispatch(removeItemFromPrinterInState(stripItemId));
    dispatch(addAnimatingStripItemInState(stripItemId));
    addStripItem(stripItemId, position);
    invokeHubMethod(
      "MoveStripItem",
      { facilityId, itemId: stripItemId, position },
      "Moved strip item",
      "Error moving strip item",
    );
    setTimeout(() => {
      animateDraggingStripItemToDroppedStripItem(stripItemId);
    });
  };

  // Public
  const getDraggingStripElement = (stripItemId: string) => {
    return document.querySelector(`[data-strip-item-clone-id='${stripItemId}']`) as HTMLDivElement;
  };

  // Public
  const moveDraggingStripItem = (position: StripItemPosition, toBayButton?: boolean) => {
    const { stripItemId } = draggingStripItemSpec!;
    if (position.facilityId === facilityId) {
      addStripItem(stripItemId, position);
    }
    dispatch(addAnimatingStripItemInState(stripItemId));
    dispatch(setDraggingStripItemSpecInState(undefined));
    dispatch(setDisplacingPositionInState(undefined));
    if (toBayButton) {
      dispatch(removeAnimatingStripItemInState(stripItemId));
      animateDraggingStrip(stripItemId, position.bayId);
    } else {
      setTimeout(() => {
        animateDraggingStripItemToDroppedStripItem(stripItemId);
      });
    }
    invokeHubMethod(
      "MoveStripItem",
      { facilityId, itemId: stripItemId, position },
      "Moved strip item",
      "Error moving strip item",
    );
  };

  // Public
  const deleteDraggingStripItem = () => {
    const { stripItemId } = draggingStripItemSpec!;
    dispatch(addAnimatingStripItemInState(stripItemId));
    dispatch(setDraggingStripItemSpecInState(undefined));
    dispatch(setDisplacingPositionInState(undefined));
    dispatch(removeAnimatingStripItemInState(stripItemId));
    animateDraggingStrip(stripItemId);
    invokeHubMethod(
      "DeleteStripItem",
      { facilityId, itemId: stripItemId },
      "Deleted strip item",
      "Error deleting strip item",
    );
  };

  // Public
  const returnDraggingStripItem = () => {
    const { stripItemId, origin } = draggingStripItemSpec!;
    const position = activeBayId === origin.bayId ? origin : getFirstPositionOnRack(activeBayId, 0);
    addStripItem(stripItemId, position);
    dispatch(addAnimatingStripItemInState(stripItemId));
    dispatch(setDraggingStripItemSpecInState(undefined));
    dispatch(setDisplacingPositionInState(undefined));
    setTimeout(() => {
      animateDraggingStripItemToDroppedStripItem(stripItemId);
    });
    invokeHubMethod(
      "MoveStripItem",
      { facilityId, itemId: stripItemId, position },
      "Moved strip item",
      "Error moving strip item",
    );
  };

  const animateDraggingStripItemToDroppedStripItem = (stripItemId: string) => {
    const draggingStripItemElement = getDraggingStripElement(stripItemId);
    const stripElement = document.querySelector(`[data-strip-item-id='${stripItemId}']`) as HTMLElement;

    const fromRect = draggingStripItemElement.getBoundingClientRect();
    const toRect = stripElement.getBoundingClientRect();
    const heightAdjustment = (fromRect.height - toRect.height) / 2;
    const widthAdjustment = (fromRect.width - toRect.width) / 2;

    const xDelta = toRect.left - fromRect.left - widthAdjustment;
    const yDelta = toRect.top - fromRect.top - heightAdjustment;

    draggingStripItemElement.style.zIndex = "49";
    draggingStripItemElement.style.opacity = "1";
    draggingStripItemElement.style.boxShadow = "none";
    draggingStripItemElement.style.pointerEvents = "none";
    draggingStripItemElement.style.transform = `translate(${xDelta}px, ${yDelta}px)`;

    setTimeout(() => {
      draggingStripItemElement.remove();
      dispatch(removeAnimatingStripItemInState(stripItemId));
      dispatch(setAnimationsDisabledInState(false));
    }, DROP_ANIMATION_MS);
  };

  const animateDraggingStrip = (stripItemId: string, toBayId?: string) => {
    const draggingStripItemElement = getDraggingStripElement(stripItemId);
    const fromRect = draggingStripItemElement.getBoundingClientRect();
    const toRect = document
      .querySelector(toBayId ? `[data-bay-button-id='${toBayId}']` : "[data-trash]")!
      .getBoundingClientRect();
    draggingStripItemElement.style.transitionProperty = "scale, opacity, left, top, transform";
    draggingStripItemElement.style.scale = ".3";
    draggingStripItemElement.style.boxShadow = "none";
    draggingStripItemElement.style.transform = "rotate(-20deg)";
    draggingStripItemElement.style.transition = `${DROP_ANIMATION_MS}ms ease-in`;
    draggingStripItemElement.style.left = `${(toRect.left + toRect.right) / 2 - fromRect.width / 2}px`;
    draggingStripItemElement.style.top = `${toRect.top}px`;

    setTimeout(() => {
      draggingStripItemElement.remove();
      dispatch(setAnimationsDisabledInState(false));
      if (!toBayId) {
        trashSound.play();
      }
    }, DROP_ANIMATION_MS);
  };

  const functions = useMemo(
    () => ({
      setFacility,
      getBayId,
      getBay,
      getStripItem,
      setActiveBay,
      cycleActiveBay,
      getFirstPositionOnRack,
      getStripItemPosition,
      selectAdjacentStripItem,
      focusStripItemField,
      createStripItem,
      moveSelectedStripItem,
      cycleStripItemType,
      toggleStripItemOffset,
      editStripItemType,
      pushStripItemToBay,
      dragStripItem,
      deleteStripItem,
      editStripItemField,
      getDraggingStripElement,
      returnDraggingStripItem,
      moveDraggingStripItem,
      moveStripItemFromPrinter,
      deleteDraggingStripItem,
    }),
    [
      setFacility,
      getBayId,
      getBay,
      getStripItem,
      setActiveBay,
      cycleActiveBay,
      getFirstPositionOnRack,
      getStripItemPosition,
      selectAdjacentStripItem,
      focusStripItemField,
      createStripItem,
      moveSelectedStripItem,
      cycleStripItemType,
      toggleStripItemOffset,
      editStripItemType,
      pushStripItemToBay,
      dragStripItem,
      deleteStripItem,
      editStripItemField,
      getDraggingStripElement,
      returnDraggingStripItem,
      moveDraggingStripItem,
      moveStripItemFromPrinter,
      deleteDraggingStripItem,
    ],
  );

  return <FlightStripsContext.Provider value={functions}>{children}</FlightStripsContext.Provider>;
}

const useFlightStrips = () => {
  return useContext(FlightStripsContext);
};

export default useFlightStrips;
