import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StripItemType } from "@vatsim-vnas/js-libs/models/vnas/messaging";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useFlightStrips, useHub, useKey } from "src/hooks";
import {
  configurationSelector,
  facilityIdSelector,
  isReadOnlySelector,
  printerItemIdsSelector,
  printerMenuIsActiveSelector,
  setPrinterMenuIsActive,
  useAppDispatch,
  useAppSelector,
} from "src/redux";
import * as S from "src/styles/menus";
import { Button, Hr, Input } from "src/styles/ui";
import Printer, { PrinterHandle } from "./Printer";

function PrinterMenu() {
  const printerMenuIsActive = useAppSelector(printerMenuIsActiveSelector);
  const configuration = useAppSelector(configurationSelector);
  const printerItemIds = useAppSelector(printerItemIdsSelector);
  const isReadOnly = useAppSelector(isReadOnlySelector);
  const facilityId = useAppSelector(facilityIdSelector);
  const placeholderText = useMemo(() => (Math.random() < 0.99 ? "AAL123" : "PRM4211"), []);
  const dispatch = useAppDispatch();

  const { getStripItem } = useFlightStrips();
  const hub = useHub();
  const [requestedAircraftId, setRequestedAircraftId] = useState("");
  const [editingInput, setEditingInput] = useState(false);
  const inputRef = useRef<HTMLInputElement>(undefined!);
  const departurePrinterRef = useRef<PrinterHandle>(undefined!);

  const departurePrinterItemIds = printerItemIds.filter((i) => getStripItem(i).type !== StripItemType.ArrivalStrip);
  const arrivalPrinterItemIds = printerItemIds.filter((i) => getStripItem(i).type === StripItemType.ArrivalStrip);

  const handlePrint = () => {
    (async () => {
      if (requestedAircraftId) {
        try {
          await hub.invoke("RequestFlightStrip", facilityId, requestedAircraftId.toUpperCase());
          departurePrinterRef.current.resetCurrentStripNumber();
        } catch (e) {
          if (`${e}`.includes("No flight plan found")) {
            toast.warning("No flight plan found", { autoClose: 5000 });
          }
        }
        setRequestedAircraftId("");
      }
    })();
  };

  const handlePrintBlankStrip = () => {
    (async () => {
      await hub.invoke("RequestBlankStrip", facilityId);
      departurePrinterRef.current.resetCurrentStripNumber();
    })();
  };

  useKey("Tab", () => dispatch(setPrinterMenuIsActive(false)), {
    printerMenuIsActive: true,
    alternateKeys: ["Escape"],
  });
  useKey("Enter", handlePrint, { printerMenuIsActive: true, isReadOnly: false, disabled: !editingInput });

  useEffect(() => {
    if (printerMenuIsActive) {
      inputRef.current?.focus();
    }
  }, [printerMenuIsActive]);

  if (!printerMenuIsActive || !configuration) {
    return undefined;
  }

  return (
    <>
      <S.Screen onClick={() => dispatch(setPrinterMenuIsActive(false))} />
      <S.PrinterMenu>
        <S.MenuContent>
          <S.CloseButton onClick={() => dispatch(setPrinterMenuIsActive(false))}>
            <FontAwesomeIcon icon={faTimes} />
          </S.CloseButton>
          <S.Title>
            Flight Strip Printer{configuration.enableArrivalStrips && configuration.enableSeparateArrDepPrinters && "s"}
          </S.Title>
          <S.PrinterControlsWrapper>
            <Input
              ref={inputRef}
              placeholder={placeholderText}
              style={{ textTransform: "uppercase" }}
              value={requestedAircraftId}
              onFocus={() => setEditingInput(true)}
              onBlur={() => setEditingInput(false)}
              onChange={(e) => setRequestedAircraftId(e.target.value)}
              disabled={isReadOnly}
            />
            <Button className="success" onClick={handlePrint} disabled={requestedAircraftId === "" || isReadOnly}>
              Request Strip
            </Button>
          </S.PrinterControlsWrapper>
          <div>
            <Button className="primary" onClick={handlePrintBlankStrip} disabled={isReadOnly}>
              Print Blank Strip
            </Button>
          </div>
          <Hr />
          {configuration.enableArrivalStrips && configuration.enableSeparateArrDepPrinters ? (
            <>
              <S.Description>Departure Printer:</S.Description>
              <Printer
                printerItemIds={departurePrinterItemIds}
                disableKeyBinds={editingInput}
                emptyMessage="No new departure flight strips"
                ref={departurePrinterRef}
              />
              <Hr />
              <S.Description>Arrival Printer:</S.Description>
              <Printer
                printerItemIds={arrivalPrinterItemIds}
                disableKeyBinds={editingInput}
                emptyMessage="No new arrival flight strips"
              />
            </>
          ) : (
            <Printer
              printerItemIds={printerItemIds}
              disableKeyBinds={editingInput}
              emptyMessage="No new flight strips"
              ref={departurePrinterRef}
            />
          )}
        </S.MenuContent>
      </S.PrinterMenu>
    </>
  );
}

export default PrinterMenu;
