import { StripItemType } from "@vatsim-vnas/js-libs/models/vnas/messaging";
import React, { DragEvent, useEffect, useRef, useState } from "react";
import { ArrivalStrip, BlankStrip, DepartureStrip, HalfStrip, Separator } from "src/components/flightStrips";
import { useFlightStrips } from "src/hooks";
import { StripItemPosition } from "src/models";
import {
  animatingStripItemIdsSelector,
  animationsDisabledSelector,
  displacingPositionSelector,
  isReadOnlySelector,
  selectedStripItemIdSelector,
  setSelectedStripItem,
  useAppDispatch,
  useAppSelector,
} from "src/redux";
import * as S from "src/styles/flightStrips";

interface StripItemProps {
  stripItemId: string;
}

const determineIfDisplaced = (displacedPosition: StripItemPosition | undefined, position: StripItemPosition) => {
  if (!displacedPosition) {
    return false;
  }
  return displacedPosition.rack === position.rack && displacedPosition.index <= position.index;
};

function StripItem({ stripItemId }: Readonly<StripItemProps>) {
  const { getStripItem, dragStripItem, deleteStripItem, getStripItemPosition, toggleStripItemOffset } =
    useFlightStrips();
  const isSelected = useAppSelector(selectedStripItemIdSelector) === stripItemId;
  const isDisplaced = determineIfDisplaced(
    useAppSelector(displacingPositionSelector),
    getStripItemPosition(stripItemId)!,
  );
  const isHidden = useAppSelector(animatingStripItemIdsSelector).includes(stripItemId);
  const isReadOnly = useAppSelector(isReadOnlySelector);
  const animationsDisabled = useAppSelector(animationsDisabledSelector);
  const dispatch = useAppDispatch();
  const [isHoldingShift, setIsHoldingShift] = useState(false);
  const [isHoldingCtrl, setIsHoldingCtrl] = useState(false);
  const [isHoldingAlt, setIsHoldingAlt] = useState(false);
  const stripItem = getStripItem(stripItemId);
  const stripItemRef = useRef<HTMLDivElement>(undefined!);

  useEffect(() => {
    if (isSelected) {
      stripItemRef.current.scrollIntoView();
    }
  }, [isSelected]);

  const handleClick = () => {
    if (!stripItem.isSeparator() && isHoldingShift) {
      toggleStripItemOffset(stripItemId);
    } else if (isHoldingCtrl) {
      dispatch(setSelectedStripItem(stripItemId));
    } else if (isHoldingAlt) {
      deleteStripItem(stripItemId);
    }
  };

  const handleDrag = (e: DragEvent) => {
    e.preventDefault();
    if (!isReadOnly) {
      dragStripItem(stripItemId, e.pageX, e.pageY);
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Shift") {
      setIsHoldingShift(true);
      e.preventDefault();
    }
    if (e.key === "Control") {
      setIsHoldingCtrl(true);
      e.preventDefault();
    }
    if (e.key === "Alt") {
      setIsHoldingAlt(true);
      e.preventDefault();
    }
  };

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === "Shift") {
      setIsHoldingShift(false);
      e.preventDefault();
    }
    if (e.key === "Control") {
      setIsHoldingCtrl(false);
      e.preventDefault();
    }
    if (e.key === "Alt") {
      setIsHoldingAlt(false);
      e.preventDefault();
    }
  };

  const handleMouseMove = (e: MouseEvent) => {
    setIsHoldingShift(e.shiftKey);
    setIsHoldingCtrl(e.ctrlKey);
    setIsHoldingAlt(e.altKey);
  };

  useEffect(() => {
    if (!isReadOnly) {
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("keyup", handleKeyUp);
      document.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isReadOnly]);

  const getStripItemElement = () => {
    if (stripItem.type === StripItemType.ArrivalStrip) {
      return <ArrivalStrip stripItem={stripItem} />;
    }
    if (stripItem.type === StripItemType.BlankStrip) {
      return <BlankStrip stripItem={stripItem} />;
    }
    if (stripItem.isSeparator()) {
      return <Separator stripItem={stripItem} />;
    }
    if (stripItem.isHalfStrip()) {
      return <HalfStrip stripItem={stripItem} />;
    }
    return <DepartureStrip stripItem={stripItem} />;
  };

  return (
    <S.StripHolder
      $animationsDisabled={animationsDisabled}
      $isDisplaced={isDisplaced}
      $isHidden={isHidden}
      $isHoldingAlt={isHoldingAlt}
      $isHoldingCtrl={isHoldingCtrl}
      $isOffset={stripItem.isOffset}
      $isReadOnly={isReadOnly}
      className={isSelected ? "selected" : ""}
      data-strip-item-id={stripItemId}
      draggable
      onClick={handleClick}
      onDragStart={handleDrag}
      ref={stripItemRef}
    >
      {getStripItemElement()}
      {stripItem.isDisconnected && <S.DisconnectedX />}
    </S.StripHolder>
  );
}

export default StripItem;
