import { DEPARTURE_STRIP_FIELDS_MAP } from "@vatsim-vnas/js-libs/constants";
import { StripItemDto } from "@vatsim-vnas/js-libs/models/vnas/messaging";
import React, { MouseEvent } from "react";
import { ContextMenuState } from "src/enums";
import {
  configurationSelector,
  isReadOnlySelector,
  setContextMenuSpec,
  setSelectedStripItem,
  useAppDispatch,
  useAppSelector,
} from "src/redux";
import * as S from "src/styles/flightStrips";
import AnnotationBox from "./AnnotationBox";

interface DepartureStripProps {
  stripItem: StripItemDto;
}

function DepartureStrip({ stripItem }: Readonly<DepartureStripProps>) {
  const isReadOnly = useAppSelector(isReadOnlySelector);
  const configuration = useAppSelector(configurationSelector)!;
  const dispatch = useAppDispatch();

  const handleContextMenu = (e: MouseEvent) => {
    if (!isReadOnly) {
      e.preventDefault();
      e.stopPropagation();
      dispatch(setSelectedStripItem(stripItem.id));
      dispatch(setContextMenuSpec({ state: ContextMenuState.FlightStrip, x: e.pageX, y: e.pageY }));
    }
  };

  return (
    <S.FlightPlanStrip onContextMenu={handleContextMenu}>
      <S.StripField x={15} y={52}>
        {stripItem.fieldValues[DEPARTURE_STRIP_FIELDS_MAP.get("aircraft-id")!]}
      </S.StripField>
      <S.StripField x={15} y={39} fontSize={10}>
        {stripItem.fieldValues[DEPARTURE_STRIP_FIELDS_MAP.get("revision")!]}
      </S.StripField>
      <S.StripField x={15} y={27}>
        {stripItem.fieldValues[DEPARTURE_STRIP_FIELDS_MAP.get("equipment")!]}
      </S.StripField>
      <S.StripField x={15} y={2}>
        {stripItem.fieldValues[DEPARTURE_STRIP_FIELDS_MAP.get("cid")!]}
      </S.StripField>
      {configuration.displayBarcodes && (
        <S.Barcode>{`${stripItem.fieldValues[DEPARTURE_STRIP_FIELDS_MAP.get("cid")!]}${
          stripItem.fieldValues[DEPARTURE_STRIP_FIELDS_MAP.get("cid")!]
        }`}</S.Barcode>
      )}
      <S.VerticalLine x={120} />
      <S.StripField x={122} y={52}>
        {stripItem.fieldValues[DEPARTURE_STRIP_FIELDS_MAP.get("beacon")!]}
      </S.StripField>
      <S.HorizontalLine x={120} y={22} width={45} />
      <S.StripField x={122} y={27}>
        {stripItem.fieldValues[DEPARTURE_STRIP_FIELDS_MAP.get("p-time")!]}
      </S.StripField>
      <S.HorizontalLine x={120} y={44} width={45} />
      <S.StripField x={122} y={2}>
        {stripItem.fieldValues[DEPARTURE_STRIP_FIELDS_MAP.get("altitude")!]}
      </S.StripField>
      <S.VerticalLine x={165} />
      <S.StripField x={170} y={52}>
        {stripItem.fieldValues[DEPARTURE_STRIP_FIELDS_MAP.get("departure")!]}
      </S.StripField>
      {configuration.displayDestinationAirportIds && (
        <S.StripField x={210} y={52}>
          {stripItem.fieldValues[DEPARTURE_STRIP_FIELDS_MAP.get("destination")!]}
        </S.StripField>
      )}
      <S.VerticalLine x={240} />
      <S.StripField x={245} y={52}>
        {stripItem.fieldValues[DEPARTURE_STRIP_FIELDS_MAP.get("route-1")!]}
      </S.StripField>
      <S.StripField x={245} y={27}>
        {stripItem.fieldValues[DEPARTURE_STRIP_FIELDS_MAP.get("route-2")!]}
      </S.StripField>
      <S.StripField x={245} y={2}>
        {stripItem.fieldValues[DEPARTURE_STRIP_FIELDS_MAP.get("route-3")!]}
      </S.StripField>
      <S.VerticalLine x={445} />
      <S.VerticalLine x={475} />
      <S.VerticalLine x={505} />
      <S.HorizontalLine x={445} y={22} width={90} />
      <S.HorizontalLine x={445} y={44} width={90} />
      <AnnotationBox
        x={445}
        y={44}
        stripItem={stripItem}
        fieldNumber={DEPARTURE_STRIP_FIELDS_MAP.get("annotation-1")!}
      />
      <AnnotationBox
        x={475}
        y={44}
        stripItem={stripItem}
        fieldNumber={DEPARTURE_STRIP_FIELDS_MAP.get("annotation-2")!}
      />
      <AnnotationBox
        x={505}
        y={44}
        stripItem={stripItem}
        fieldNumber={DEPARTURE_STRIP_FIELDS_MAP.get("annotation-3")!}
      />
      <AnnotationBox
        x={445}
        y={22}
        stripItem={stripItem}
        fieldNumber={DEPARTURE_STRIP_FIELDS_MAP.get("annotation-4")!}
      />
      <AnnotationBox
        x={475}
        y={22}
        stripItem={stripItem}
        fieldNumber={DEPARTURE_STRIP_FIELDS_MAP.get("annotation-5")!}
      />
      <AnnotationBox
        x={505}
        y={22}
        stripItem={stripItem}
        fieldNumber={DEPARTURE_STRIP_FIELDS_MAP.get("annotation-6")!}
      />
      <AnnotationBox
        x={445}
        y={0}
        stripItem={stripItem}
        fieldNumber={DEPARTURE_STRIP_FIELDS_MAP.get("annotation-7")!}
      />
      <AnnotationBox
        x={475}
        y={0}
        stripItem={stripItem}
        fieldNumber={DEPARTURE_STRIP_FIELDS_MAP.get("annotation-8")!}
      />
      <AnnotationBox
        x={505}
        y={0}
        stripItem={stripItem}
        fieldNumber={DEPARTURE_STRIP_FIELDS_MAP.get("annotation-9")!}
      />
    </S.FlightPlanStrip>
  );
}

export default DepartureStrip;
