import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getFlightStripsConfigurationAsync } from "@vatsim-vnas/js-libs/api/vnas";
import { FlightStripsConfiguration, StripBay } from "@vatsim-vnas/js-libs/models/facilities";
import { FlightStripsStateDto, StripBayContentsDto, StripItemDto } from "@vatsim-vnas/js-libs/models/vnas/messaging";
import { instanceToInstance } from "class-transformer";
import { toast } from "react-toastify";
import { DraggingStripItemSpec } from "src/models";
import { processResponse } from "src/utils";
import { RootState } from "../store";

interface FlightStripsState {
  facilityId?: string;
  configuration?: FlightStripsConfiguration;
  stripItems: StripItemDto[];
  baysContents: StripBayContentsDto[];
  externalBays: StripBay[];
  printerItemIds: string[];
  draggingStripItemSpec?: DraggingStripItemSpec;
}

const initialState: FlightStripsState = {
  stripItems: [],
  baysContents: [],
  externalBays: [],
  printerItemIds: [],
};

export const getFlightStripsConfiguration = createAsyncThunk(
  "flightStrips/getFlightStripsConfiguration",
  async (facilityId: string, thunkApi) => {
    const { environment, session } = (thunkApi.getState() as RootState).auth;
    const res = await getFlightStripsConfigurationAsync(environment!.apiBaseUrl, session!.artccId, facilityId);
    return processResponse(res, `Failed to get flight strips configuration: ${res.statusText}`);
  },
);

const flightStripsSlice = createSlice({
  name: "flightStrips",
  initialState,
  reducers: {
    setFacility(state, action: { payload: string | undefined }) {
      state.facilityId = action.payload;
      if (!action.payload) {
        document.title = "vStrips";
      } else {
        document.title = `vStrips | ${action.payload}`;
      }
      state.configuration = undefined;
      state.baysContents = [];
      state.externalBays = [];
    },
    addOrUpdateStripItem(state, action: { payload: StripItemDto }) {
      state.stripItems = [...state.stripItems.filter((i) => i.id !== action.payload.id), action.payload];
    },
    removeStripItem(state, action: { payload: string }) {
      state.stripItems = state.stripItems.filter((i) => i.id !== action.payload);
    },
    setFlightStripsState(state, action: { payload: FlightStripsStateDto }) {
      state.baysContents = action.payload.bayItems;
      state.printerItemIds = action.payload.printerItems;
    },
    setBaysContents(state, action: { payload: StripBayContentsDto[] }) {
      state.baysContents = action.payload;
    },
    addExternalStripBay(state, action: { payload: StripBay }) {
      state.externalBays.push(action.payload);
    },
    removeExternalStripBay(state, action: { payload: string }) {
      const newConfiguration = instanceToInstance(state.configuration!);
      newConfiguration.externalBays = newConfiguration.externalBays.filter((b) => b.bayId !== action.payload);
      state.configuration = newConfiguration;
    },
    removeItemFromPrinter(state, action: { payload: string }) {
      state.printerItemIds = state.printerItemIds.filter((i) => i !== action.payload);
    },
    setDraggingStripItemSpec(state, action: { payload: DraggingStripItemSpec | undefined }) {
      state.draggingStripItemSpec = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFlightStripsConfiguration.fulfilled, (state, action) => {
      if (action.payload) {
        state.configuration = action.payload;
      }
    });
    builder.addCase(getFlightStripsConfiguration.rejected, (_, action) => {
      toast.error(`Failed to get flight strips configuration: ${action.error.message}`);
    });
  },
});

export const {
  setFacility,
  addOrUpdateStripItem,
  removeStripItem,
  setFlightStripsState,
  setBaysContents,
  addExternalStripBay,
  removeExternalStripBay,
  removeItemFromPrinter,
  setDraggingStripItemSpec,
} = flightStripsSlice.actions;

export const facilityIdSelector = (state: RootState) => state.flightStrips.facilityId;
export const configurationSelector = (state: RootState) => state.flightStrips.configuration;
export const stripItemsSelector = (state: RootState) => state.flightStrips.stripItems;
export const baysContentsSelector = (state: RootState) => state.flightStrips.baysContents;
export const externalBaysSelector = (state: RootState) => state.flightStrips.externalBays;
export const printerItemIdsSelector = (state: RootState) => state.flightStrips.printerItemIds;
export const draggingStripItemSpecSelector = (state: RootState) => state.flightStrips.draggingStripItemSpec;
export const isDraggingStripItemSelector = (state: RootState) => state.flightStrips.draggingStripItemSpec !== undefined;

export default flightStripsSlice.reducer;
