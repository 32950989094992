import { StripItemDto } from "@vatsim-vnas/js-libs/models/vnas/messaging";
import React, { FocusEvent, MutableRefObject, useEffect, useRef, useState } from "react";
import { useFlightStrips } from "src/hooks";
import {
  isReadOnlySelector,
  printerItemIdsSelector,
  setIsEditingStripItem,
  setSelectedStripItem,
  setWasFocusedFromKeyboard,
  useAppDispatch,
  useAppSelector,
  wasFocusedFromKeyboardSelector,
} from "src/redux";
import * as S from "src/styles/flightStrips";
import { replaceAnnotationSymbols } from "src/utils";

interface AnnotationBoxProps {
  fieldNumber: number;
  height?: number;
  multiLine?: boolean;
  stripItem: StripItemDto;
  textAlign?: string;
  width?: number;
  x: number;
  y: number;
}

type InputElement = HTMLInputElement | HTMLTextAreaElement;

function AnnotationBox({
  x,
  y,
  height = 22,
  width = 30,
  stripItem,
  fieldNumber,
  textAlign = "center",
  multiLine = false,
}: Readonly<AnnotationBoxProps>) {
  const isReadOnly = useAppSelector(isReadOnlySelector);
  const isInPrinter = useAppSelector(printerItemIdsSelector).includes(stripItem.id);
  const wasFocusedFromKeyboard = useAppSelector(wasFocusedFromKeyboardSelector);
  const dispatch = useAppDispatch();

  const [disablePointer, setDisablePointer] = useState(false);

  const { editStripItemField } = useFlightStrips();
  const wasFocusedFromContextMenu = useRef(false);
  const inputRef = useRef<InputElement>(undefined!);

  const handleBlur = (e: FocusEvent<InputElement>) => {
    dispatch(setIsEditingStripItem(false));
    editStripItemField(stripItem.id, fieldNumber, e.target.value);
    if (wasFocusedFromContextMenu) {
      wasFocusedFromContextMenu.current = false;
      return;
    }
    if (!wasFocusedFromKeyboard) {
      dispatch(setSelectedStripItem(undefined));
    }
  };

  const handleChange = (e: React.ChangeEvent<InputElement>) => {
    const { selectionStart, selectionEnd, value } = e.target;
    e.target.value = replaceAnnotationSymbols(value);
    e.target.setSelectionRange(selectionStart, selectionEnd);
  };

  const handleClick = () => {
    dispatch(setWasFocusedFromKeyboard(false));
  };

  const handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    wasFocusedFromContextMenu.current = true;
  };

  const handleFocus = () => {
    dispatch(setSelectedStripItem(stripItem.id));
    dispatch(setIsEditingStripItem(true));
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Escape") {
      e.stopPropagation();
      inputRef.current.blur();
    }
    if (e.key === "Enter") {
      if (!multiLine || inputRef.current.value.split("\n").length >= 3) {
        e.preventDefault();
        e.stopPropagation();
        inputRef.current.blur();
      }
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleWindowKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Control" || e.key === "Alt") {
      setDisablePointer(true);
      e.preventDefault();
    }
  };

  const handleWindowKeyUp = (e: KeyboardEvent) => {
    if (e.key === "Control" || e.key === "Alt") {
      setDisablePointer(false);
      e.preventDefault();
    }
  };

  const handleMouseMove = (e: MouseEvent) => {
    setDisablePointer(e.ctrlKey || e.altKey);
  };

  useEffect(() => {
    if (!isReadOnly) {
      document.addEventListener("keydown", handleWindowKeyDown);
      document.addEventListener("keyup", handleWindowKeyUp);
      document.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      document.removeEventListener("keydown", handleWindowKeyDown);
      document.removeEventListener("keyup", handleWindowKeyUp);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isReadOnly]);

  const disabled = isReadOnly || isInPrinter;
  const grabbable = isInPrinter && !isReadOnly;
  const value = stripItem.fieldValues[fieldNumber];

  if (multiLine) {
    return (
      <S.AnnotationArea
        $disablePointer={disablePointer}
        $grabbable={grabbable}
        $height={height}
        $textAlign={textAlign}
        $width={width}
        $x={x}
        $y={y}
        defaultValue={value}
        disabled={disabled}
        key={value}
        onBlur={handleBlur}
        onChange={handleChange}
        onClick={handleClick}
        onContextMenu={handleContextMenu}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        onMouseDown={handleMouseDown}
        ref={inputRef as MutableRefObject<HTMLTextAreaElement>}
        rows={3}
      />
    );
  }
  return (
    <S.AnnotationBox
      $grabbable={grabbable}
      $height={height}
      $textAlign={textAlign}
      $width={width}
      $x={x}
      $y={y}
      defaultValue={value}
      disabled={disabled}
      key={value}
      onBlur={handleBlur}
      onChange={handleChange}
      onClick={handleClick}
      onContextMenu={handleContextMenu}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      onMouseDown={handleMouseDown}
      ref={inputRef as MutableRefObject<HTMLInputElement>}
    />
  );
}

export default AnnotationBox;
