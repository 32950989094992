import React, { useEffect, useId, useState } from "react";
import useFlightStrips from "src/hooks/useFlightStrips";
import {
  activeBayIdSelector,
  configurationSelector,
  externalBaysSelector,
  hoveringOverBayButtonIdSelector,
  useAppSelector,
} from "src/redux";
import * as S from "src/styles/header";

interface BayButtonProps {
  bayId: string;
  bayIndex: number;
  facility?: string;
  name?: string;
}

function BayButton({ bayId, bayIndex, name = undefined, facility = undefined }: Readonly<BayButtonProps>) {
  const activeBayId = useAppSelector(activeBayIdSelector);
  const externalBays = useAppSelector(externalBaysSelector);
  const configuration = useAppSelector(configurationSelector)!;
  const isHovering = useAppSelector(hoveringOverBayButtonIdSelector) === bayId;

  const { setActiveBay, getBayId } = useFlightStrips();
  const [showBayIndices, setShowBayIndices] = useState(false);

  const id = useId();

  const handleKeyDown = (e: KeyboardEvent) => {
    if ((e.key === "Alt" && e.ctrlKey) || (e.key === "Control" && e.altKey)) {
      setShowBayIndices(true);
      e.preventDefault();
    }
  };

  const handleKeyUp = (e: KeyboardEvent) => {
    if ((e.key === "Alt" && e.ctrlKey) || (e.key === "Control" && e.altKey)) {
      setShowBayIndices(false);
      e.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  if (!facility) {
    return (
      <S.BayButton
        $isHovering={isHovering && bayId !== activeBayId}
        $selected={activeBayId === bayId}
        data-bay-button-id={bayId}
        id={id}
        onClick={() => setActiveBay(getBayId(bayIndex))}
      >
        {name}
        {showBayIndices && bayIndex < 9 && activeBayId !== bayId && <S.BayIndex>{bayIndex + 1}</S.BayIndex>}
      </S.BayButton>
    );
  }
  return (
    <S.BayButton key={bayId} data-bay-button-id={bayId} data-bay-button-external-facility-id={facility} $isExternal>
      {`${facility} - ${externalBays.find((eb) => eb.id === bayId)?.name ?? ""}`}
      {showBayIndices && bayIndex + configuration.stripBays.length < 9 && (
        <S.BayIndex>{bayIndex + configuration.stripBays.length + 1}</S.BayIndex>
      )}
    </S.BayButton>
  );
}

export default BayButton;
