import { faServer } from "@fortawesome/free-solid-svg-icons";
import { frequencyToString } from "@vatsim-vnas/js-libs/utils";
import React from "react";
import { environmentSelector, sessionSelector, useAppSelector } from "src/redux";
import * as S from "src/styles/login";

interface CurrentLoginProps {
  showSessionStatus: boolean;
}

function CurrentLogin({ showSessionStatus }: Readonly<CurrentLoginProps>) {
  const session = useAppSelector(sessionSelector);
  const environment = useAppSelector(environmentSelector)!;
  const primaryPosition = session?.getPrimaryPosition();

  return (
    <S.CurrentLogin>
      {primaryPosition
        ? `${primaryPosition.radioName} (${frequencyToString(primaryPosition.frequency)})`
        : "No vNAS Connection"}
      {showSessionStatus && session && <div>Session {session.isActive ? "Active" : "Inactive"}</div>}
      <div>
        <small>
          <S.ServerIcon icon={faServer} />
          {environment.name}
        </small>
      </div>
    </S.CurrentLogin>
  );
}

export default CurrentLogin;
